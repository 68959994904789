import React, { useState, useEffect, useMemo } from "react";
import Logo from "../Assets/logo.svg";
import { styled } from '@mui/material/styles';
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { HiOutlineX } from "react-icons/hi";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const NavbarContainer = styled('nav')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  transition: 'top 0.5s',
});

const Navbar2 = () => {
  const { t } = useTranslation();
  const location = useLocation(); // Get current path
  const [openMenu, setOpenMenu] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  const menuOptions = useMemo(
    () => [
      { text: t("home"), link: "Home" },
      { text: t("about"), link: "About" },
      { text: t("contact"), link: "Contact" },
    ],
    [t]
  );

  return (
    <NavbarContainer style={{ top: visible ? "0" : "-150px" }}>
      <div className="nav-logo-container">
        <a href="/">
          <img
            className="company-logo"
            src={Logo}
            alt="company-logo"
            width={100}
          />
        </a>
      </div>
      <div className="navbar-links-container">
        {/* <a className="underline-hover" href="/">
          {t("home")}
        </a>
        <a className="underline-hover" href="/about">
          {t("about")}
        </a>
        <a className="underline-hover" href="/contact">
          {t("contact")}
        </a> */}
        {menuOptions.map((item) => (
          <a
            key={item.link}
            className={`underline-hover ${
              location.pathname === `/${item.link.toLowerCase()}` ||
              (location.pathname === "/" && item.link === "Home")
                ? "active"
                : ""
            }`}
            href={`/${item.link.toLowerCase()}`}
          >
            {item.text}
          </a>
        ))}
        <LanguageSwitcher />
      </div>

      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchor="top"
        PaperProps={{
          sx: {
            borderRadius: "0 0 40px 40px", // Adjust the value as per your preference
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem",
            height: "10px",
          }}
        >
          <LanguageSwitcher />
        </Box>
        <Box
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "1rem",
              height: "10px",
            }}
          >
            <HiOutlineX
              onClick={() => setOpenMenu(false)}
              style={{ fontSize: "24px", margin: "0.2rem" }}
            />{" "}
            {/* Close icon at top right corner */}
          </Box>
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text}>
                <ListItemButton
                  component="a"
                  href={`/${item.link.toLowerCase()}`}
                  style={{ textAlign: "center" }}
                >
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </NavbarContainer>
  );
};

export default Navbar2;
